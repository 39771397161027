//Calculo del Precio Estimado

export function farehelper(distance, time, rateDetails, coin) {
  if (coin === "SOL") {
    let ratePerKm = rateDetails.rate_per_kilometer_es;
    let ratePerHour = rateDetails.rate_per_hour_es;
    let ratePerSecond = ratePerHour / 3600;
    let minFare = rateDetails.min_fare_es;
    let DistanceInKM = parseFloat(distance / 1000).toFixed(2);
    let estimateRateForKM = parseFloat(DistanceInKM * ratePerKm).toFixed(2) * 1;
    let estimateRateForhour = parseFloat(time * ratePerSecond).toFixed(2);

    let total =
      parseFloat(estimateRateForKM) + parseFloat(estimateRateForhour) > minFare
        ? parseFloat(estimateRateForKM) + parseFloat(estimateRateForhour)
        : minFare;

    let convenienceFee = (total * rateDetails.convenience_fees_es) / 100;

    let grandtotal = parseFloat(total) + parseFloat(convenienceFee);
    let calculateData = {
      distaceRate: estimateRateForKM,
      timeRate: estimateRateForhour,
      totalCost: total,
      grandTotal: grandtotal,
      convenience_fees: convenienceFee,
    };

    return calculateData;
  } else {
    let ratePerKm = rateDetails.rate_per_kilometer;
    let ratePerHour = rateDetails.rate_per_hour;
    let ratePerSecond = ratePerHour / 3600;
    let minFare = rateDetails.min_fare;
    let DistanceInKM = parseFloat(distance / 1000).toFixed(2);
    let estimateRateForKM = parseFloat(DistanceInKM * ratePerKm).toFixed(2) * 1;
    let estimateRateForhour = parseFloat(time * ratePerSecond).toFixed(2);

    let total =
      parseFloat(estimateRateForKM) + parseFloat(estimateRateForhour) > minFare
        ? parseFloat(estimateRateForKM) + parseFloat(estimateRateForhour)
        : minFare;

    let convenienceFee = (total * rateDetails.convenience_fees) / 100;

    let grandtotal = parseFloat(total) + parseFloat(convenienceFee);
    let calculateData = {
      distaceRate: estimateRateForKM,
      timeRate: estimateRateForhour,
      totalCost: total,
      grandTotal: grandtotal,
      convenience_fees: convenienceFee,
    };
    return calculateData;
  }
}
