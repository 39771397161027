import React, { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import HeaderLinks from "components/Header/HeaderLinks.js";

import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import WatchLaterIcon from "@material-ui/icons/WatchLater";

//para el nuevo
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import CustomInput from "components/CustomInput/CustomInput.js";
import InputAdornment from "@material-ui/core/InputAdornment";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import {
  Paper,
  FormControl,
  Select,
  MenuItem,
  TextField,
} from "@material-ui/core";
import GoogleMapsAutoComplete from "../components/GoogleMapsAutoComplete";
import styles from "assets/jss/material-kit-react/views/presupuestoPage.js";

import { getEstimate, clearEstimate } from "../actions/estimateactions";
import {
  getEstimatePerHour,
  clearEstimatePerHour,
} from "../actions/estimateactionshour";
import { addBooking, clearBooking } from "../actions/bookingactions";
import { useSelector, useDispatch } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import AlertDialog from "../components/AlertDialog";
import languageJson from "../config/language";
import { useTranslation } from "react-i18next";
import image from "assets/img/background.jpg";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function PresupuestoPage(props) {
  const classes = useStyles();
  const { t } = useTranslation(["language"]);
  const dispatch = useDispatch();
  const { ...rest } = props;
  const cartypes = useSelector((state) => state.cartypes.cars);
  const estimatedata = useSelector((state) => state.estimatedata);
  const bookingdata = useSelector((state) => state.bookingdata);
  const settings = useSelector((state) => state.settingsdata.settings);
  const [carType, setCarType] = useState(t("select_car"));
  const [pickupAddress, setPickupAddress] = useState(null);
  const [dropAddress, setDropAddress] = useState(null);
  const [estimateModalStatus, setEstimateModalStatus] = React.useState(false);
  const [selectedCarDetails, setSelectedCarDetails] = useState(null);
  const auth = useSelector((state) => state.auth);
  const [commonAlert, setCommonAlert] = useState({ open: false, msg: "" });
  const [bookingType, setBookingType] = useState("Book Now");
  const [comentario, setComentario] = useState(null);
  const [bookingCoin, setBookingCoin] = useState("SOL");
  const [bookingDist, setBookingDist] = useState("KM");

  //agregado
  const [loginType, setLoginType] = React.useState(0);
  const [tabDisabled] = React.useState(false);
  const [activeReg] = React.useState(false);

  const [data, setData] = React.useState({
    email: "",
    pass: "",
    country: "",
    mobile: "",
    password: "",
    otp: "",
    verificationId: null,
    firstName: "",
    lastName: "",
  });

  const [check, setCheck] = React.useState({
    checked: true,
  });

  const checkChange = (event) => {
    setCheck({ ...check, [event.target.name]: event.target.checked });
  };

  const onInputChange = (event) => {
    setComentario(event.target.value);
  };

  const handleTabChange = (event, newValue) => {
    setLoginType(newValue);
  };

  const getDateString = (date) => {
    let d = null;
    d = date ? new Date(date) : new Date();

    let month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear(),
      hours = d.getHours(),
      mins = d.getMinutes();
    if ((month >= 1) & (month <= 9)) month = "0" + month.toString();
    if ((day >= 0) & (day <= 9)) day = "0" + day.toString();
    if ((hours >= 0) & (hours <= 9)) hours = "0" + hours.toString();
    if ((mins >= 0) & (mins <= 9)) mins = "0" + mins.toString();

    return [year, month, day].join("-") + "T" + [hours, mins].join(":");
  };

  const dateDiff2 = (dateIn, dateFi) => {
    const dateIni = new Date(dateIn);
    const dateFin = new Date(dateFi);
    const diffTime = dateFin - dateIni;
    return Math.ceil(diffTime / (1000 * 60 * 60));
  };

  const dateDiff = (date) => {
    const date1 = new Date();
    const date2 = new Date(date);
    const diffTime = date2 - date1;
    return diffTime / (1000 * 60);
  };

  const [selectedDate, setSelectedDate] = React.useState(getDateString());
  const [selectedDateIni, setSelectedDateIni] = React.useState(getDateString());
  const [selectedDateFin, setSelectedDateFin] = React.useState(getDateString());

  const handleCarSelect = (event) => {
    setCarType(event.target.value);
    let carDetails = null;
    for (let i = 0; i < cartypes.length; i++) {
      if (cartypes[i].name === event.target.value) {
        carDetails = cartypes[i];
      }
    }
    setSelectedCarDetails(carDetails);
  };

  const handleBookCoinSelect = (event) => {
    setBookingCoin(event.target.value);
  };

  const handleBookDistSelect = (event) => {
    setBookingDist(event.target.value);
  };

  const handleBookTypeSelect = (event) => {
    setBookingType(event.target.value);
    if (bookingType === "Book Later") {
      setSelectedDate(getDateString());
    }
  };

  const onDateChange = (event) => {
    setSelectedDate(event.target.value);
  };
  const onDateChangeIni = (event) => {
    setSelectedDateIni(event.target.value);
  };
  const onDateChangeFin = (event) => {
    setSelectedDateFin(event.target.value);
  };

  useEffect(() => {
    if (estimatedata.estimate) {
      setEstimateModalStatus(true);
    }
  }, [estimatedata.estimate]);

  const handleGetEstimate = (e) => {
    e.preventDefault();
    if (auth.info) {
      if (pickupAddress && dropAddress && selectedCarDetails) {
        console.log("Moneda" + bookingCoin);
        if (bookingType === "Book Now") {
          dispatch(
            getEstimate({
              pickup: pickupAddress,
              drop: dropAddress,
              carDetails: selectedCarDetails,
              bookingCoin: bookingCoin,
            })
          );
        } else {
          if (bookingType === "Book Later" && selectedDate) {
            if (dateDiff(selectedDate) >= 15) {
              dispatch(
                getEstimate({
                  pickup: pickupAddress,
                  drop: dropAddress,
                  carDetails: selectedCarDetails,
                  bookingCoin: bookingCoin,
                })
              );
            } else {
              setCommonAlert({
                open: true,
                msg: t("past_booking_error"),
              });
            }
          } else {
            setCommonAlert({ open: true, msg: t("select_proper") });
          }
        }
      } else {
        setCommonAlert({ open: true, msg: t("select_proper") });
      }
    } else {
      setCommonAlert({ open: true, msg: t("must_login") });
    }
  };

  const handleGetEstimatePerHour = (e) => {
    e.preventDefault();
    if (auth.info) {
      var tiempoReserva = dateDiff2(selectedDateIni, selectedDateFin);
      console.log("tiempo es " + tiempoReserva);
      if (
        pickupAddress &&
        dropAddress &&
        selectedCarDetails &&
        selectedDateIni &&
        selectedDateFin
      ) {
        if (bookingType === "Book Now") {
          dispatch(
            getEstimatePerHour({
              pickup: pickupAddress,
              drop: dropAddress,
              carDetails: selectedCarDetails,
              timeTotal: tiempoReserva,
              bookingCoin: bookingCoin,
            })
          );
        } else {
          if (bookingType === "Book Later") {
            if (dateDiff(selectedDateIni) >= 5) {
              dispatch(
                getEstimatePerHour({
                  pickup: pickupAddress,
                  drop: dropAddress,
                  carDetails: selectedCarDetails,
                  timeTotal: tiempoReserva,
                  bookingCoin: bookingCoin,
                })
              );
            } else {
              setCommonAlert({
                open: true,
                msg: t("past_booking_error"),
              });
            }
          } else {
            setCommonAlert({ open: true, msg: t("select_proper") });
          }
        }
      } else {
        setCommonAlert({ open: true, msg: t("select_proper") });
      }
    } else {
      setCommonAlert({ open: true, msg: t("must_login") });
    }
  };

  const confirmBooking = (e) => {
    e.preventDefault();
    setEstimateModalStatus(false);
    dispatch(
      addBooking({
        pickup: pickupAddress,
        drop: dropAddress,
        carDetails: selectedCarDetails,
        userDetails: auth.info,
        estimate: estimatedata.estimate,
        tripdate: new Date(selectedDate).toString(),
        tripdateIni: new Date(selectedDateIni).toString(),
        tripdateFin: new Date(selectedDateFin).toString(),
        bookLater: bookingType === "Book Later" ? true : false,
        settings: settings,
        comment: comentario,
        check: check,
        bookingCoin: bookingCoin,
      })
    );
  };

  const handleEstimateModalClose = (e) => {
    e.preventDefault();
    setEstimateModalStatus(false);
    dispatch(clearEstimate());
    dispatch(clearEstimatePerHour());
  };

  const handleEstimateErrorClose = (e) => {
    e.preventDefault();
    dispatch(clearEstimate());
    dispatch(clearEstimatePerHour());
  };

  const handleBookingAlertClose = (e) => {
    e.preventDefault();
    dispatch(clearBooking());
    dispatch(clearEstimate());
    dispatch(clearEstimatePerHour());
    props.history.push("/booking-history");
  };

  const handleBookingErrorClose = (e) => {
    e.preventDefault();
    dispatch(clearBooking());
  };

  const handleCommonAlertClose = (e) => {
    e.preventDefault();
    setCommonAlert({ open: false, msg: "" });
  };

  return (
    <div>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white",
        }}
        {...rest}
      />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center",
        }}
      >
        <div>
          <GridContainer>
            {cartypes ? (
              <div className={classes.container}>
                <GridContainer spacing={2} justify="center">
                  <GridItem xs={12} sm={12} md={6} lg={6}>
                    <br />
                    <br />
                    <br />
                    <h1 className={classes.title}>{t("book_now_title")}</h1>
                  </GridItem>
                </GridContainer>
                <GridContainer spacing={2}>
                  <GridItem xs={12} sm={12} md={6} lg={6}>
                    <Paper square className={classes.root}>
                      <Tabs
                        value={loginType}
                        onChange={handleTabChange}
                        variant="fullWidth"
                        indicatorColor="primary"
                        textColor="primary"
                        aria-label="switch login type"
                      >
                        <Tab
                          disabled={tabDisabled}
                          icon={<LocalShippingIcon />}
                          label={t("title_route")}
                        />
                        <Tab
                          disabled={tabDisabled}
                          icon={<WatchLaterIcon />}
                          label={t("title_perHour")}
                        />
                      </Tabs>
                    </Paper>
                  </GridItem>
                </GridContainer>

                {!activeReg || activeReg ? (
                  <GridContainer spacing={2}>
                    <GridItem xs={6} sm={6} md={3} lg={3}>
                      <FormControl style={{ width: "100%", justify: "center" }}>
                        <Select
                          id="booking-type-native"
                          value={bookingCoin}
                          className={classes.input}
                          onChange={handleBookCoinSelect}
                          inputProps={{ "aria-label": "Without label" }}
                        >
                          <MenuItem key={"USD"} value={"USD"}>
                            USD ($)
                          </MenuItem>
                          <MenuItem key={"SOL"} value={"SOL"}>
                            SOL (S/)
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </GridItem>
                    <GridItem xs={6} sm={6} md={3} lg={3}>
                      <FormControl style={{ width: "100%", justify: "center" }}>
                        <Select
                          id="booking-type-native"
                          value={bookingDist}
                          className={classes.input}
                          onChange={handleBookDistSelect}
                          disabled
                          inputProps={{ "aria-label": "Without label" }}
                        >
                          <MenuItem key={"KM"} value={"KM"}>
                            km
                          </MenuItem>
                          <MenuItem key={"MI"} value={"MI"}>
                            mi
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </GridItem>
                  </GridContainer>
                ) : null}

                {!activeReg || activeReg ? ( //Ubicacion Inicio
                  <GridContainer spacing={2}>
                    <GridItem xs={12} sm={12} md={6} lg={6}>
                      <Paper>
                        <GoogleMapsAutoComplete
                          placeholder={t("pickup_location")}
                          onChange={(value) => {
                            setPickupAddress(value);
                          }}
                        />
                      </Paper>
                    </GridItem>
                  </GridContainer>
                ) : null}

                {!activeReg || activeReg ? ( //Ubicacion Fin
                  <GridContainer spacing={2}>
                    <GridItem xs={12} sm={12} md={6} lg={6}>
                      <Paper>
                        <GoogleMapsAutoComplete
                          placeholder={t("drop_location")}
                          onChange={(value) => {
                            setDropAddress(value);
                          }}
                        />
                      </Paper>
                    </GridItem>
                  </GridContainer>
                ) : null}

                {!activeReg || activeReg ? ( //Carro y reserva Ahora
                  <GridContainer spacing={2}>
                    <GridItem xs={6} sm={6} md={3} lg={3}>
                      <FormControl style={{ width: "100%" }}>
                        <Select
                          id="car-type-native"
                          value={carType}
                          onChange={handleCarSelect}
                          className={
                            carType === "select_car"
                              ? classes.inputdimmed
                              : classes.input
                          }
                        >
                          <MenuItem value={t("select_car")} key={"select_car"}>
                            {t("select_car")}
                          </MenuItem>
                          {cartypes.map((car) => (
                            <MenuItem key={car.name} value={car.name}>
                              <img
                                src={car.image}
                                className={classes.carphoto}
                                alt="car types"
                              />
                              {car.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </GridItem>
                    <GridItem xs={6} sm={6} md={3} lg={3}>
                      <FormControl style={{ width: "100%" }}>
                        <Select
                          id="booking-type-native"
                          value={bookingType}
                          className={classes.input}
                          onChange={handleBookTypeSelect}
                          inputProps={{ "aria-label": "Without label" }}
                        >
                          <MenuItem key={"Book Now"} value={"Book Now"}>
                            {t("book_now")}
                          </MenuItem>
                          <MenuItem key={"Book Later"} value={"Book Later"}>
                            {t("book_later")}
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </GridItem>
                  </GridContainer>
                ) : null}
                {(loginType === 0 && !activeReg) ||
                (activeReg && loginType === 1) ? ( //Calendario
                  <GridContainer spacing={2}>
                    {bookingType === "Book Later" ? (
                      <GridItem xs={6} sm={6} md={4} lg={4}>
                        <TextField
                          id="datetime-local"
                          label={t("booking_date_time")}
                          type="datetime-local"
                          variant="filled"
                          fullWidth
                          className={classes.commonInputStyle}
                          InputProps={{
                            className: classes.input,
                          }}
                          value={selectedDate}
                          onChange={onDateChange}
                        />
                      </GridItem>
                    ) : null}
                  </GridContainer>
                ) : null}

                {(loginType === 1 && !activeReg) ||
                (loginType === 0 && activeReg) ? ( //Calendario Inicio
                  <GridContainer spacing={2}>
                    <GridItem xs={6} sm={6} md={4} lg={4}>
                      <TextField
                        id="datetime-local-ini"
                        label={t("booking_date_time_ini")}
                        type="datetime-local"
                        variant="filled"
                        className={classes.commonInputStyle}
                        InputProps={{
                          className: classes.input,
                        }}
                        value={selectedDateIni}
                        onChange={onDateChangeIni}
                      />
                    </GridItem>
                  </GridContainer>
                ) : null}
                {(loginType === 1 && !activeReg) ||
                (loginType === 0 && activeReg) ? ( //Calendario Fin
                  <GridContainer spacing={2}>
                    <GridItem xs={6} sm={6} md={4} lg={4}>
                      <TextField
                        id="datetime-local-fin"
                        label={t("booking_date_time_end")}
                        type="datetime-local"
                        variant="filled"
                        className={classes.commonInputStyle}
                        InputProps={{
                          className: classes.input,
                        }}
                        value={selectedDateFin}
                        onChange={onDateChangeFin}
                      />
                    </GridItem>
                  </GridContainer>
                ) : null}

                {!activeReg || activeReg ? ( //Cartel
                  <GridContainer spacing={2}>
                    <GridItem xs={6} sm={6} md={6} lg={6}>
                      <Paper>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={check.checkedB}
                              onChange={checkChange}
                              name="checked"
                              color="primary"
                            />
                          }
                          label={t("Poster")}
                        />
                      </Paper>
                    </GridItem>
                  </GridContainer>
                ) : null}

                {!activeReg || activeReg ? ( //Comentarios
                  <GridContainer spacing={2}>
                    <GridItem xs={6} sm={6} md={6} lg={6}>
                      <Paper>
                        <CustomInput
                          labelText={t("Comments")}
                          id="commets"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            type: "text",
                            required: true,
                            autoComplete: "off",
                          }}
                          onChange={onInputChange}
                          value={comentario}
                        />
                      </Paper>
                    </GridItem>
                  </GridContainer>
                ) : null}

                {(loginType === 0 && !activeReg) ||
                (activeReg && loginType === 1) ? ( //Boton por Ruta
                  <GridContainer spacing={2}>
                    <GridItem xs={6} sm={6} md={6} lg={6}>
                      <Button
                        color="success"
                        size="lg"
                        rel="noopener noreferrer"
                        className={classes.items}
                        onClick={handleGetEstimate}
                      >
                        <i className="fas fa-car" />
                        {t("book_now")}
                      </Button>
                    </GridItem>
                  </GridContainer>
                ) : null}

                {(loginType === 1 && !activeReg) ||
                (activeReg && loginType === 0) ? ( //Boton de Por Hora
                  <GridContainer spacing={2}>
                    <GridItem xs={6} sm={6} md={6} lg={6}>
                      <Button
                        color="success"
                        size="lg"
                        rel="noopener noreferrer"
                        className={classes.items}
                        onClick={handleGetEstimatePerHour}
                      >
                        <i className="fas fa-car" />
                        {t("book_now")}
                      </Button>
                    </GridItem>
                  </GridContainer>
                ) : null}
              </div>
            ) : null}
          </GridContainer>
        </div>
        <Footer whiteFont />
        <Dialog
          open={estimateModalStatus}
          onClose={handleEstimateModalClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{t("estimate")}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {bookingCoin}
              {estimatedata.estimate
                ? estimatedata.estimate.estimateFare
                : null}
              {estimatedata.estimate
                ? estimatedata.estimate.estimateFareHour
                : null}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleEstimateModalClose} color="google">
              {t("cancel")}
            </Button>
            <Button onClick={confirmBooking} color="success" autoFocus>
              {t("book")}
            </Button>
          </DialogActions>
        </Dialog>

        {/* Guardar Reserva Conformada y satisfactoria */}
        <AlertDialog
          open={bookingdata.booking ? true : false}
          onClose={handleBookingAlertClose}
        >
          {bookingdata.booking
            ? t("booking_success") + bookingdata.booking.booking_id
            : null}
        </AlertDialog>
        {/* Error de Datos para la reserva */}
        <AlertDialog
          open={bookingdata.error.flag}
          onClose={handleBookingErrorClose}
        >
          {bookingdata.error.msg}
        </AlertDialog>
        {/* Error de calculo del estimado */}
        <AlertDialog
          open={estimatedata.error.flag}
          onClose={handleEstimateErrorClose}
        >
          {estimatedata.error.msg}
        </AlertDialog>
        {/* Alertas */}
        <AlertDialog open={commonAlert.open} onClose={handleCommonAlertClose}>
          {commonAlert.msg}
        </AlertDialog>
      </div>
    </div>
  );
}
