/*eslint-disable*/
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { List, ListItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/components/footerStyle.js";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Info, AccountBox, House } from "@material-ui/icons";

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();
  const { t } = useTranslation(["language"]);
  const auth = useSelector((state) => state.auth);
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    if (auth.info) {
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    }
  }, [auth.info]);

  const { whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont,
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont,
  });
  return (
    <footer className={footerClasses}>
      <div className={classes.container}>
        <div className={classes.left}>
          <List className={classes.list}>
            <ListItem className={classes.inlineBlock}>
              <a href="/" className={classes.block}>
                {t("home")}
              </a>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <a href="/booking-history" className={classes.block}>
                {loggedIn ? t("myaccount") : t("login_signup")}
              </a>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <a href="/about-us" className={classes.block}>
                {t("about_us")}
              </a>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <a href="/privacy-policy" className={classes.block}>
                {t("privacy_policy")}
              </a>
            </ListItem>
          </List>
        </div>
        <div className={classes.right}>
          &copy; {1900 + new Date().getYear() + " "}
          <a href="https://taximundo.com" className={aClasses} target="_blank">
            Taxi Mundo
          </a>
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  whiteFont: PropTypes.bool,
};
