import React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";

//para el nuevo
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import CardBody from "components/Card/CardBody.js";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import WatchLaterIcon from "@material-ui/icons/WatchLater";
import { Paper } from "@material-ui/core";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import ProductSection from "./Sections/ProductSection.js";
import SectionDownload from "./Sections/SectionDownload.js";
import { useTranslation } from "react-i18next";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function LandingPage(props) {
  const classes = useStyles();

  const { ...rest } = props;

  const { t } = useTranslation(["language"]);

  //agregado
  const [loginType, setLoginType] = React.useState(0);
  const [tabDisabled] = React.useState(false);
  const [activeReg] = React.useState(false);

  const handleTabChange = (event, newValue) => {
    setLoginType(newValue);
  };

  return (
    <div>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white",
        }}
        {...rest}
      />
      <Parallax filter image={require("assets/img/background.jpg")}>
        <div className={classes.container}>
          <GridContainer spacing={2} justify="center">
            <GridItem xs={12} sm={12} md={6} lg={6}>
              <br />
              <h1 className={classes.title}>{t("book_now_title")}</h1>
            </GridItem>
          </GridContainer>
          <GridContainer spacing={2}>
            <GridItem xs={12} sm={12} md={6} lg={6}>
              <Paper square className={classes.root}>
                <Tabs
                  value={loginType}
                  onChange={handleTabChange}
                  variant="fullWidth"
                  indicatorColor="primary"
                  textColor="inherit"
                  aria-label="switch login type"
                >
                  <Tab
                    disabled={tabDisabled}
                    label={t("title_route")}
                    icon={<LocalShippingIcon />}
                  />
                  <Tab
                    disabled={tabDisabled}
                    label={t("title_perHour")}
                    icon={<WatchLaterIcon />}
                  />
                </Tabs>
              </Paper>
            </GridItem>
          </GridContainer>

          {!activeReg || activeReg ? ( //INFORMACION
            <GridContainer spacing={2}>
              <GridItem xs={12} sm={12} md={6} lg={6}>
                <Button
                  color="success"
                  size="lg"
                  rel="noopener noreferrer"
                  className={classes.items}
                  href="/presupuestos"
                >
                  <i className="fas fa-car" />
                  {t("information")}
                </Button>
              </GridItem>
            </GridContainer>
          ) : null}
        </div>
      </Parallax>
      <CardBody></CardBody>
      <CardBody></CardBody>

      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <ProductSection />
        </div>
      </div>
      <div className={classNames(classes.main2, classes.mainRaised2)}>
        <div className={classes.container}>
          <SectionDownload />
        </div>
      </div>
      <Footer />
    </div>
  );
}
