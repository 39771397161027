/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Select, MenuItem } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";
import { Info, AccountBox, House, LocalTaxi } from "@material-ui/icons";
import Button from "components/CustomButtons/Button.js";
import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";
import { useSelector } from "react-redux";
import languageJson from "../../config/language";
import reactDOM from "react-dom";
import { useTranslation } from "react-i18next";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import NativeSelect from "@material-ui/core/NativeSelect";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/Inbox";
import DraftsIcon from "@material-ui/icons/Drafts";

import { Modal, TextField } from "@material-ui/core";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const classes = useStyles();
  const auth = useSelector((state) => state.auth);
  const [loggedIn, setLoggedIn] = useState(false);
  const { t, i18n } = useTranslation(["language"]);
  const [open, setOpen] = React.useState(false);
  const [modal, setModal] = useState(false);
  const [idioma, setIdioma] = useState("ESP");

  const abrirModal = () => {
    setModal(!modal);
  };

  const espaniol = (event) => {
    i18n.changeLanguage("es");
    setModal(!modal);
  };

  const ingles = (event) => {
    i18n.changeLanguage("en");
    setModal(!modal);
  };

  const body = (
    <div className={classes.modal}>
      <Button key={"ESP"} value={"ESP"} onClick={espaniol} color="google">
        ESP
      </Button>
      <Button key={"ENG"} value={"ENG"} onClick={ingles} color="google">
        ENG
      </Button>
    </div>
  );

  useEffect(() => {
    if (auth.info) {
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    }
  }, [auth.info]);

  const [age, setAge] = React.useState("");

  const handleIdiomaSelect = (event) => {
    setIdioma(event.target.value);
  };

  const handleChange = (event) => {
    //setAge(event.target.value);
    console.log(event.target.value);
    i18n.changeLanguage(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpenLan = () => {
    setOpen(true);
  };
  const handleClickOpenDis = () => {
    setOpen(true);
  };
  const handleClickOpenMon = () => {
    setOpen(true);
  };

  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <Button href="/" color="transparent" className={classes.navLink}>
          <House className={classes.icons} />
          {t("home")}
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          href="/booking-history"
          color="transparent"
          className={classes.navLink}
        >
          {loggedIn ? (
            <AccountBox className={classes.icons} />
          ) : (
            <AccountBox className={classes.icons} />
          )}

          {loggedIn ? t("myaccount") : t("login_signup")}
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          href="/presupuestos"
          color="transparent"
          className={classes.navLink}
        >
          <LocalTaxi className={classes.icons} />
          {t("bookings")}
        </Button>
      </ListItem>

      <ListItem className={classes.listItem}>
        <Button
          href="/about-us"
          color="transparent"
          className={classes.navLink}
        >
          <Info className={classes.icons} />
          {t("about_us")}
        </Button>
      </ListItem>

      <ListItem className={classes.listItem}>
        <Button
          onClick={() => abrirModal()}
          className={classes.navLink}
          color="transparent"
          value={idioma}
        >
          {idioma}
        </Button>
        <Modal open={modal} onClose={abrirModal}>
          {body}
        </Modal>
      </ListItem>
    </List>
  );
}
