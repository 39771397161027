import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";

import styles from "assets/jss/material-kit-react/views/staticPages.js";
import Parallax from "components/Parallax/Parallax";
import { useTranslation } from "react-i18next";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function AboutUs(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const { t } = useTranslation(["language"]);
  return (
    <div>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white",
        }}
        {...rest}
      />
      <Parallax small filter image={require("assets/img/header-back.jpg")} />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <br />
          <h2 className={classes.title}>{t("AboutUs_title")}</h2>
          <p className={classes.description}>{t("AboutUs_content1")}</p>
          <p className={classes.description}>{t("AboutUs_content2")}</p>
          <h2 className={classes.title}>{t("AboutUs_mission")}</h2>
          <p className={classes.description}>{t("AboutUs_content_mission")}</p>
          <h2 className={classes.title}>{t("AboutUs_vision")}</h2>
          <p className={classes.description}>{t("AboutUs_content_vision")}</p>
          <h2 className={classes.title}>{t("AboutUs_contract")}</h2>
          <p className={classes.description}>{t("AboutUs_content_contract")}</p>
          <br />
        </div>
      </div>

      <Footer />
    </div>
  );
}
