export const FirebaseConfig = {
  apiKey: "AIzaSyA6q4NZkPPLJ7Qb-sZCAiFGp-u14g62t6Q",
  authDomain: "taximundorn.firebaseapp.com",
  databaseURL: "https://taximundorn.firebaseio.com",
  projectId: "taximundorn",
  storageBucket: "taximundorn.appspot.com",
  messagingSenderId: "584533393123",
  appId: "1:584533393123:web:1a00ac49eb626bc6ae8fd6",
  measurementId: "G-CLE5X6T4YB",
};

export const google_map_key = "AIzaSyB2sVUeiuSJDsWOeKkxLzIOvJkYUMoV2s4";
